import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';


const PrivacyPolicy = () => {
  return (
    <Container className="privacy-policy">
      <Row>
        <Col>
          <h1>Privacy Policy</h1>
          <p>Welcome to ConnectionUnlimited.net! Your privacy is important to us. This Privacy Policy outlines how we collect, use, and protect your personal information when you visit our website and use our services.</p>
          
          <h2>1. Information We Collect</h2>
          <h3>Personal Information</h3>
          <p>We may collect personal information such as your name, email address, and any other details you provide when you register or interact with our site.</p>
          <h3>Usage Data</h3>
          <p>We collect information about your interactions with our site, including IP addresses, browser types, pages visited, and the time spent on our site.</p>
          
          <h2>2. How We Use Your Information</h2>
          <h3>To Improve Our Services</h3>
          <p>We use your information to enhance and personalize your experience on ConnectionUnlimited.net.</p>
          <h3>To Communicate With You</h3>
          <p>We may use your contact information to send updates, promotional materials, or respond to your inquiries.</p>
          <h3>For Analytics</h3>
          <p>We analyze usage data to understand user behavior and improve our website’s functionality and content.</p>
          
          <h2>3. Cookies and Tracking Technologies</h2>
          <p>ConnectionUnlimited.net uses cookies and similar tracking technologies to enhance your experience. Cookies help us remember your preferences and provide relevant content. You can manage cookie settings through your browser.</p>
          
          <h2>4. Third-Party Services</h2>
          <p>We may use third-party services for analytics, advertising, and other functions. These third parties may collect information about your interactions with our site. We do not control their practices and encourage you to review their privacy policies.</p>
          
          <h2>5. Data Security</h2>
          <p>We implement reasonable security measures to protect your information from unauthorized access, alteration, or disclosure. However, no method of transmission over the internet is 100% secure.</p>
          
          <h2>6. Your Rights</h2>
          <h3>Access and Correction</h3>
          <p>You can access and update your personal information by contacting us.</p>
          <h3>Opt-Out</h3>
          <p>You may opt out of receiving promotional emails by following the unsubscribe instructions provided in those communications.</p>
          
          <h2>7. Changes to This Privacy Policy</h2>
          <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page, and your continued use of ConnectionUnlimited.net constitutes acceptance of those changes.</p>
          
          <p>Thank you for visiting ConnectionUnlimited.net. Your privacy and trust are important to us.</p>
        </Col>
      </Row>
    </Container>
  );
};

export default PrivacyPolicy;
