import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

const Card = ({ post }) => {
  const imageUrl = `https://www.connectionunlimited.net/gamedata/uploads/${post.image}`;
  return (
    <Link to={`/blog/${post.slug}`} className="text-decoration-none">
      <div className="card cardMainDesign">
        <img src={imageUrl} alt={post.title} width="400" height="300" className="card-img-top" />
        <div className="card-body">
          <h5 className="card-title">{post.title}</h5>
          <p className="card-text">{post.meta_description}</p>
          <a href={`/blog/${post.slug}`} className="btn btn-link">Read more</a>
          <hr />
          <div className='d-flex justify-content-between align-items-center'>
            <div className='d-flex align-items-center'>
              <img src={`/author.png`} alt={post.author} width="20" height="20" className="me-2" />
              <h5 className='mb-0'>{post.author}</h5>
            </div>
            <div className='d-flex align-items-center'>
              <FontAwesomeIcon icon={faCalendarAlt} className="me-2" />
              <h5 className='mb-0'>{post.date}</h5>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default Card;
