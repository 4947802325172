import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

function Footer() {
  return (
    <footer className="bg-light text-dark py-4">
      <Container>
        <Row>
          <Col className="text-center">
          <img className='my-2' src='/logo.png' width={250} height={60} />
            <ul className="list-unstyled d-flex justify-content-center">
            <li className="mx-3">
                <a href="/blog" className="text-decoration-none">Blog</a>
              </li>
            <li className="mx-3">
                <a href="/" className="text-decoration-none">Game</a>
              </li>
              <li className="mx-3">
                <a href="/about-us" className="text-decoration-none">About Us</a>
              </li>
              <li className="mx-3">
                <a href="/contact-us" className="text-decoration-none">Contact Us</a>
              </li>
              <li className="mx-3">
                <a href="/privacy-policy" className="text-decoration-none">Privacy Policy</a>
              </li>
            </ul>
            <p className="mt-3 mb-0">&copy; {new Date().getFullYear()} Connections Unlimited. All rights reserved.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
