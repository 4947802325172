import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function AboutUs() {
  return (
    <Container className="my-5 about-us">
      <Row className="justify-content-center">
        <Col md={8} lg={6}>
          <h1 className="mb-4 text-center">About Us</h1>
          <p className="lead">
            Welcome to Connections Unlimited, where we turn puzzle-solving into an exhilarating adventure! Our team is passionate about creating games that challenge, entertain, and inspire players. Connections Unlimited is the culmination of our dedication to crafting a unique gaming experience that blends creativity with strategic thinking.
          </p>
          <p>
            At Connections Unlimited, we believe that puzzles should be more than just a pastime—they should be an engaging journey that keeps you coming back for more. Our game is designed to offer endless possibilities with its innovative approach to connecting words, phrases, and images. Whether you're a seasoned puzzle enthusiast or a casual gamer, Connections Unlimited provides a captivating experience that evolves with every playthrough.
          </p>
          <p>
            Our commitment to excellence is reflected in every aspect of the game, from its dynamic gameplay to its stunning visuals. We’re constantly working to enhance your experience with regular updates and new challenges. Join our growing community of players and discover why Connections Unlimited is becoming a favorite among puzzle lovers everywhere.
          </p>
          <p>
            Thank you for choosing <a href="http://www.ConnectionUnlimited.net" target="_blank" rel="noopener noreferrer">ConnectionsUnlimited.net</a>. Dive in, make connections, and enjoy the endless fun!
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export default AboutUs;
