import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';


const faqs = [
  {
    question: "What makes Connections Unlimited different from other puzzle games?",
    answer: "Connections Unlimited stands out with its limitless potential for creating connections and its blend of creative and strategic gameplay. Unlike traditional puzzles, it offers a dynamic experience where each playthrough can be unique."
  },
  {
    question: "Can I play Connections Unlimited offline?",
    answer: "Currently, Connections Unlimited requires an internet connection to access its full features and multiplayer options. However, offline mode for solo puzzles is being considered for future updates."
  },
  {
    question: "How do I improve my skills in Connections Unlimited?",
    answer: "Practice is key! Playing different levels and experimenting with various connection strategies can enhance your skills. Additionally, reviewing and analyzing your previous games can help you understand and improve your approach."
  },
  {
    question: "Are there any in-game purchases or ads in Connections Unlimited?",
    answer: "Connections Unlimited offers optional in-game purchases for customization and hints. While the game is free to play, occasional ads may appear to support its development."
  },
  {
    question: "Can I create my own puzzles in Connections Unlimited?",
    answer: "At this time, the game does not support user-created puzzles. However, there are plans to introduce this feature in future updates, allowing players to design and share their own challenges."
  },
  {
    question: "How often are new levels or updates added to Connections Unlimited?",
    answer: "New levels and updates are added regularly to keep the gameplay fresh and exciting. Stay tuned to the game’s official channels for announcements about new content and features."
  },
  {
    question: "Is Connections Unlimited suitable for all age groups?",
    answer: "Yes, Connections Unlimited is designed to be enjoyable for players of all ages. Its intuitive gameplay and adjustable difficulty levels make it accessible and fun for both kids and adults."
  },
  {
    question: "How can I connect with other players or join the Connections Unlimited community?",
    answer: "You can connect with other players through the game’s multiplayer mode or join online communities and forums dedicated to Connections Unlimited. Follow the game’s social media pages for updates and community events."
  }
];

function FAQSection() {
  return (
    <div className="faq-section">
      <h2 className="faq-heading">FAQ’S</h2>
      <Accordion defaultActiveKey="0">
        {faqs.map((faq, index) => (
          <Card className="faq-card" key={index}>
            <Accordion.Item eventKey={index.toString()}>
              <Accordion.Header>{faq.question}</Accordion.Header>
              <Accordion.Body>
                {faq.answer}
              </Accordion.Body>
            </Accordion.Item>
          </Card>
        ))}
      </Accordion>
    </div>
  );
}

export default FAQSection;
