import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Card from './components/Card';
import StylishSpinner from './components/StylishSpinner';

const Blog = () => {
  const [posts, setPosts] = useState([]);
  const [displayedPosts, setDisplayedPosts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const postsPerPage = 6; // Number of posts to display per page

  // Fetch categories from the API
  const fetchCategories = async () => {
    try {
      const response = await axios.get('https://www.connectionunlimited.net/gamedata/api/categories.php');
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  // Fetch posts based on the selected category
  const fetchPosts = async (categoryId) => {
    setLoading(true);
    try {
      const response = await axios.get('https://www.connectionunlimited.net/gamedata/api/blog.php');
      const filteredPosts = categoryId 
        ? response.data.data.filter(post => post.category_name === categoryId)
        : response.data.data;
      
      setPosts(filteredPosts);
      setDisplayedPosts(filteredPosts.slice(0, postsPerPage));
      setHasMore(filteredPosts.length > postsPerPage);
      setPage(2); // Reset page number to 2 after filtering
    } catch (error) {
      console.error('Error fetching posts:', error);
    } finally {
      setLoading(false);
    }
  };

  // Load more posts based on the current category
  const loadMorePosts = () => {
    setLoading(true);
    const startIndex = (page - 1) * postsPerPage;
    const endIndex = startIndex + postsPerPage;

    if (startIndex >= posts.length) {
      setHasMore(false);
      setLoading(false);
      return;
    }

    setTimeout(() => {
      setDisplayedPosts(prevDisplayedPosts => [
        ...prevDisplayedPosts,
        ...posts.slice(startIndex, endIndex),
      ]);
      setPage(prevPage => prevPage + 1);
      setLoading(false);
    }, 500); // Simulate loading delay
  };

  // Handle category change
  const handleCategoryChange = (categoryId) => {
    setSelectedCategory(categoryId);
    fetchPosts(categoryId);
  };

  useEffect(() => {
    fetchCategories();
    fetchPosts(null); // Fetch all posts initially
  }, []);

  return (
    <div className='container'>
      <div className="text-center my-2">
        <button 
          className={`btn btn-${selectedCategory === null ? 'success' : ''}`}
          onClick={() => handleCategoryChange(null)}
        >
          All Posts
        </button>
        {categories.map(category => (
          <button 
            key={category.id} 
            className={`m-2 btn btn-${selectedCategory === category.name ? 'success' : ''}`} 
            onClick={() => handleCategoryChange(category.name)}
          >
            {category.name}
          </button>
        ))}
      </div>
      <div className='row'>
        {displayedPosts.map(post => (
          <div key={post.id} className='col-md-4'>
            <Card post={post} />
          </div>
        ))}
      </div>
      {loading && (
        <div className="spinner-container">
        <StylishSpinner />
      </div>
      )}
      {hasMore && !loading && (
        <div className='text-center my-5'>
          <button onClick={loadMorePosts} className="btn btn-primary">Load More</button>
        </div>
      )}
      {!hasMore && !loading && displayedPosts.length > 0 && (
        <div className='text-center my-5'>
          <p>No more posts available</p>
        </div>
      )}
    </div>
  );
};

export default Blog;
