import React from 'react'
import { Container, Row, Col, Card, Button, Accordion } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { 
    FaInfinity, 
    FaGamepad, 
    FaUsers, 
    FaCog, 
    FaPaintBrush 
  } from 'react-icons/fa';
import FAQSection from './FAQSection';

function HomePageContent() {
    
  return (
   <>
   <div className='key-features-section py-5'>
    <div className='container'>
    <h1 className="mb-4 text-center section-title">Connections Unlimited Game</h1>  
<p className='text-center'>

Play NYT Connections Unlimited Game , the exhilarating, Wordle-inspired upgrade of the classic NYT Connections Game. Enjoy limitless fun as you enhance your vocabulary and conquer ever-evolving word challenges. With engaging gameplay and regular updates, NYT Connections Unlimited Game promises endless entertainment and mental stimulation for players of all ages. Jump in now and discover the ultimate word game thrill!
</p>

<h2 className='mt-4 text-center'>Introduction of Connections Unlimited Game</h2>
<p className='text-center'>
    Get ready to dive into endless excitement with the Connections Unlimited game! This thrilling puzzle adventure is more than just a game; it's a journey that challenges your creativity and sharpens your mind. Imagine the satisfaction of finding the perfect connections, each one sparking a little victory in your day. Whether you're competing with friends or enjoying a quiet moment alone, the Connections Unlimited game brings joy, laughter, and a sense of achievement. No wonder Connections Unlimited NYT is capturing the hearts of puzzle lovers everywhere. Get ready to connect, conquer, and celebrate!
</p>
</div>
</div>
<div className="container my-5">
            <h2 className="text-center mb-4">How to Play Connections Unlimited Game</h2>
            <div className="row">
                <div className="col-md-4">
                    <Card className="how-to-play-card">
                        <Card.Body>
                            <Card.Title>Step 1: Choose Your Puzzle</Card.Title>
                            <Card.Text>
                                Start by selecting a puzzle from the game’s menu. The game offers various difficulty levels, so pick one that suits your skill level.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
                <div className="col-md-4">
                    <Card className="how-to-play-card">
                        <Card.Body>
                            <Card.Title>Step 2: Understand the Goal</Card.Title>
                            <Card.Text>
                                Your objective is to connect related words, phrases, or images in a logical and meaningful way. The more precise your connections, the higher your score.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
                <div className="col-md-4">
                    <Card className="how-to-play-card">
                        <Card.Body>
                            <Card.Title>Step 3: Make Connections</Card.Title>
                            <Card.Text>
                                Click or tap on the items you believe are related. Drag and link them together to form connections. Use any hints or clues provided by the game to help you.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-md-4">
                    <Card className="how-to-play-card">
                        <Card.Body>
                            <Card.Title>Step 4: Review and Adjust</Card.Title>
                            <Card.Text>
                                Before you submit, review all your connections. If something seems off, make adjustments to ensure your connections are accurate.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
                <div className="col-md-4">
                    <Card className="how-to-play-card">
                        <Card.Body>
                            <Card.Title>Step 5: Submit Your Answers</Card.Title>
                            <Card.Text>
                                Once you're satisfied with your connections, submit them to see your score. The game will evaluate your connections based on accuracy and creativity.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
                <div className="col-md-4">
                    <Card className="how-to-play-card">
                        <Card.Body>
                            <Card.Title>Step 6: Level Up</Card.Title>
                            <Card.Text>
                                As you advance, puzzles will become more challenging. Keep practicing and honing your skills to tackle higher levels!
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
            </div>
           
        </div>


        <div className="key-features-section py-5">
      <Container>
        {/* Hero Section */}
        <div className="text-center mb-5">
          <h1 className="section-title">Key Features of Connections Unlimited</h1>
          <p className="section-subtitle">
            Connections Unlimited is a game that stands out for its unique and captivating features. Here's a look at what makes it so special:
          </p>
        </div>

        {/* Features Grid */}
        <Row className="g-5">
          <Col md={6} lg={4}>
            <Card className="feature-card h-100">
              <div className="icon-wrapper">
                <FaInfinity className="feature-icon" />
              </div>
              <Card.Body>
                <Card.Title>Unlimited Connections</Card.Title>
                <Card.Text>
                  Explore endless possibilities by connecting words, phrases, or images. Each session offers a fresh and exciting challenge, ensuring limitless fun.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col md={6} lg={4}>
            <Card className="feature-card h-100">
              <div className="icon-wrapper">
                <FaGamepad className="feature-icon" />
              </div>
              <Card.Body>
                <Card.Title>Engaging Gameplay</Card.Title>
                <Card.Text>
                  Challenge your creativity and problem-solving skills with intuitive and addictive gameplay that rewards you at every level.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col md={6} lg={4}>
            <Card className="feature-card h-100">
              <div className="icon-wrapper">
                <FaUsers className="feature-icon" />
              </div>
              <Card.Body>
                <Card.Title>Multiplayer Options</Card.Title>
                <Card.Text>
                  Compete with friends or players worldwide. The multiplayer mode adds excitement as you race to make the best connections quickly.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col md={6} lg={6}>
            <Card className="feature-card h-100">
              <div className="icon-wrapper">
                <FaCog className="feature-icon" />
              </div>
              <Card.Body>
                <Card.Title>Customizable Experience</Card.Title>
                <Card.Text>
                  Tailor the game to your preferences with adjustable difficulty levels, various themes, and personalized settings for a unique gameplay experience.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col md={6} lg={6}>
            <Card className="feature-card h-100">
              <div className="icon-wrapper">
                <FaPaintBrush className="feature-icon" />
              </div>
              <Card.Body>
                <Card.Title>Visual Appeal</Card.Title>
                <Card.Text>
                  Enjoy stunning visuals with vibrant colors and smooth animations that make your gaming experience immersive and visually engaging.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* Conclusion Section */}
        <div className="conclusion-section text-center mt-5">
          <h2>Conclusion</h2>
          <p>
            Connections Unlimited is a must-play for puzzle enthusiasts and creative thinkers alike. Whether playing solo or with friends, it guarantees hours of satisfying entertainment. Embrace the challenge and start making those perfect connections today!
          </p>
        
        </div>
      </Container>
    </div>

    <FAQSection />

   </>

  )
}

export default HomePageContent