import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Footer from './components/Footer';
import AboutUs from './components/AboutUs'; // Import your About Us page
import Game from './components/Game';
import PrivacyPolicy from './components/PrivacyPolicy';
import ContactUs from './components/ContactUs';
import BlogDetail from './components/BlogDetail';
import Blog from './Blog';

const App = () => {
 
  return (
    <Router>
      <>
        {/* Navigation Links */}
        <div className="bg-light border-bottom">
        <nav className="text-center">
         <a href='/'> <img className='my-2' src='/logo.png' width={250} height={60} alt="logo"/></a>
        

        </nav>
        </div>
        {/* Define Routes */}
        <Routes>
        
        <Route path="/" element={<Game />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:slug" element={<BlogDetail />} />
        </Routes>
        
        <Footer />
      </>
    </Router>
  );
};

export default App;
